import { combineReducers } from "redux";
import data from "./dataReducer";
import hatimNum from "./hatimNumReducer";
import salavatNum from "./salavatNumReducer";
import Login from "./Admin";
import InApp from "./InApp";
import fetchDua from "./Dua";
import hatimGoster from "./HatimGoster";
import fetchBaslik from "./Baslik";
import tevhidNum from "./tevhidNum";
import istigfarNum from "./istigfarNum";
import mirac from "./mirac";
import berat from "./berat";
import regaip from "./regaip";
import fatihaNum from "./fatihaNum";
import fetihNum from "./fetihNum";
import yasinNum from "./yasinNum";
import ihlasNum from "./ihlasNum";
import showFetih from "./showFetih";
import showFatiha from "./showFatiha";
import showSalavat from "./showSalavat";
import showTevhid from "./showTevhid";
import showIstigfar from "./showIstigfar";
import showYasin from "./showYasin";
import showIhlas from "./showIhlas";
import mevlid from "./mevlid";
import kadir from "./kadir";
import kurban from "./kurban";
import canakkale from "./canakkale";
import kadirNum from "./kadirNum";
import showKadir from "./showKadir";
import sifa from "./sifa";
import rahmet from "./rahmet";

export default combineReducers({
  data: data,
  hatimNum: hatimNum,
  salavatNum: salavatNum,
  auth: Login,
  ozelGunMu: InApp,
  hatimGoster: hatimGoster,
  hatimDuasi: fetchDua,
  baslik: fetchBaslik,
  tevhidNum,
  istigfarNum,
  mirac,
  regaip,
  berat,
  fatihaNum,
  fetihNum,
  yasinNum,
  ihlasNum,
  showFetih,
  showFatiha,
  showSalavat,
  showTevhid,
  showIstigfar,
  showYasin,
  showIhlas,
  mevlid,
  kadir,
  canakkale,
  kurban,
  showKadir,
  kadirNum,
  sifa,
  rahmet
});
