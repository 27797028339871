import { FETCH_REGAIP } from '../actions/types';

export default (state = "", action) => {
    switch (action.type) {
        case FETCH_REGAIP:
            return action.payload;
        default:
            return state;
    }
};
