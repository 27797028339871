import * as firebase from "firebase";

import { FirebaseConfig } from "../config/keys";
firebase.initializeApp(FirebaseConfig);

export const databaseRef = firebase.database().ref();
export const hatimRef = databaseRef.child("hatim");
export const cuzNumRef = databaseRef.child("cuzNum");
export const salavatNumRef = databaseRef.child("salavatNum");
export const tevhidNumRef = databaseRef.child("tevhidNum");
export const istigfarNumRef = databaseRef.child("istigfarNum");
export const baslikRef = databaseRef.child("baslik");
export const duaRef = databaseRef.child("dua");
export const hatimGosterRef = databaseRef.child("hatimGoster");
export const beratDuasi = databaseRef.child("beratDuasi");
export const miracDuasi = databaseRef.child("miracDuasi");
export const regaipDuasi = databaseRef.child("regaipDuasi");
export const fatihaNumRef = databaseRef.child("fatihaNum");
export const fetihNumRef = databaseRef.child("fetihNum");
export const ihlasNumRef = databaseRef.child("ihlasNum");
export const yasinNumRef = databaseRef.child("yasinNum");
export const kadirNumRef = databaseRef.child("kadirNum");
export const showSalavatRef = databaseRef.child("showSalavat");
export const showIstigfarRef = databaseRef.child("showIstigfar");
export const showTevhidRef = databaseRef.child("showTevhid");
export const showFatihaRef = databaseRef.child("showFatiha");
export const showFetihRef = databaseRef.child("showFetih");
export const showIhlasRef = databaseRef.child("showIhlas");
export const showYasinRef = databaseRef.child("showYasin");
export const showKadirRef = databaseRef.child("showKadir");
export const mevlidDuasi = databaseRef.child("mevlidDuasi");
export const kadirDuasi = databaseRef.child("kadirDuasi");
export const canakkaleDuasi = databaseRef.child("canakkaleDuasi");
export const kurbanDuasi = databaseRef.child("kurbanDuasi");
export const sifaDuasi = databaseRef.child("sifaDuasi");
export const rahmetDuasi = databaseRef.child("rahmetDuasi");
