import { FETCH_MIRAC } from '../actions/types';

export default (state = "", action) => {
    switch (action.type) {
        case FETCH_MIRAC:
            return action.payload;
        default:
            return state;
    }
};
