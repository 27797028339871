import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../actions";
import Modaller from "./Modaller";
import Button from "react-bootstrap/Button";

class Yasin extends Component {
  componentWillMount() {
    this.props.fetchYasinNum();
    this.props.fetchBaslik();
    this.props.fetchDua();
    this.props.fetchOzelGun();
  }
  renderYasinNum() {
    const { yasinNum } = this.props;
    if (isNaN(yasinNum)) {
      return (
        <center>
          <h2>Yükleniyor...</h2>
        </center>
      );
    }
    return (
      <center>
        <h3>Toplam alınmış Yasin suresi sayısı: {yasinNum}</h3>
        <h4 style={{ color: "blue" }}>
          Almak istediğiniz miktarın üstüne tıklayarak alabilirsiniz.
        </h4>
        <hr />
      </center>
    );
  }

  renderBaslik() {
    let newText = this.props.baslik
      .split("\n")
      .map((item, i) => <p key={i}>{item}</p>);
    return (
      <center>
        <hr />
        <h2>{newText}</h2>
        <hr />
      </center>
    );
  }

  renderDua() {
    let newText = this.props.hatimDuasi
      .split("\n")
      .map((item, i) => <p key={i}>{item}</p>);

    return (
      <center>
        <hr />
        <h4>
          Hatim Duası:
          <br />
          <br />
          {newText}
          <br />
          <br />
        </h4>
      </center>
    );
  }

  renderSelector() {
    return (
      <center>
        <Modaller onPositiveClick={() => this.props.takeYasin(1)}>
          <Button variant="outline-success" size="lg">
            1
          </Button>
        </Modaller>
        <br />
        <Modaller onPositiveClick={() => this.props.takeYasin(7)}>
          <Button variant="outline-success" size="lg">
            7
          </Button>
        </Modaller>
        <br />
        <Modaller onPositiveClick={() => this.props.takeYasin(21)}>
          <Button variant="outline-success" size="lg">
            21
          </Button>
        </Modaller>
        <br />
        <Modaller onPositiveClick={() => this.props.takeYasin(41)}>
          <Button variant="outline-success" size="lg">
            41
          </Button>
        </Modaller>
        <br />
        <Modaller onPositiveClick={() => this.props.takeYasin(70)}>
          <Button variant="outline-success" size="lg">
            70
          </Button>
        </Modaller>
        <br />
        <Modaller onPositiveClick={() => this.props.takeYasin(123)}>
          <Button variant="outline-success" size="lg">
            123
          </Button>
        </Modaller>
        <br />
      </center>
    );
  }

  render() {
    if (this.props.ozelGunMu) {
      return (
        <div>
          {this.renderBaslik()}
          {this.renderYasinNum()}
          {this.renderDua()}
        </div>
      );
    }
    return (
      <div>
        {this.renderBaslik()}
        {this.renderYasinNum()}
        {this.renderSelector()}
      </div>
    );
  }
}

const mapStateToProps = ({ yasinNum, baslik, ozelGunMu, hatimDuasi }) => {
  return {
    yasinNum,
    baslik,
    ozelGunMu,
    hatimDuasi
  };
};

export default connect(
  mapStateToProps,
  actions
)(Yasin);
