import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../actions";
import Modaller from "./Modaller";
import Button from "react-bootstrap/Button";

class KadirSuresi extends Component {
  componentWillMount() {
    this.props.fetchKadirNum();
    this.props.fetchBaslik();
    this.props.fetchDua();
    this.props.fetchOzelGun();
  }
  renderKadirNum() {
    const { kadirNum } = this.props;
    if (isNaN(kadirNum)) {
      return (
        <center>
          <h2>Yükleniyor...</h2>
        </center>
      );
    }
    return (
      <center>
        <h3>Toplam alınmış Kadir suresi sayısı: {kadirNum}</h3>
        <h4 style={{ color: "blue" }}>Almak istediğiniz miktarın üstüne tıklayarak alabilirsiniz.</h4>
        <hr />
      </center>
    );
  }

  renderBaslik() {
    let newText = this.props.baslik.split("\n").map((item, i) => <p key={i}>{item}</p>);
    return (
      <center>
        <hr />
        <h2>{newText}</h2>
        <hr />
      </center>
    );
  }

  renderDua() {
    let newText = this.props.hatimDuasi.split("\n").map((item, i) => <p key={i}>{item}</p>);

    return (
      <center>
        <hr />
        <h4>
          Hatim Duası:
          <br />
          <br />
          {newText}
          <br />
          <br />
        </h4>
      </center>
    );
  }

  renderSelector() {
    return (
      <center>
        <Modaller onPositiveClick={() => this.props.takeKadir(11)}>
          <Button variant="outline-success" size="lg">
            11
          </Button>
        </Modaller>
        <br />
        <Modaller onPositiveClick={() => this.props.takeKadir(21)}>
          <Button variant="outline-success" size="lg">
            21
          </Button>
        </Modaller>
        <br />
        <Modaller onPositiveClick={() => this.props.takeKadir(70)}>
          <Button variant="outline-success" size="lg">
            70
          </Button>
        </Modaller>
        <br />
        <Modaller onPositiveClick={() => this.props.takeKadir(41)}>
          <Button variant="outline-success" size="lg">
            41
          </Button>
        </Modaller>
        <br />
        <Modaller onPositiveClick={() => this.props.takeKadir(100)}>
          <Button variant="outline-success" size="lg">
            100
          </Button>
        </Modaller>
        <br />
        <Modaller onPositiveClick={() => this.props.takeKadir(500)}>
          <Button variant="outline-success" size="lg">
            500
          </Button>
        </Modaller>
        <br />
        <Modaller onPositiveClick={() => this.props.takeKadir(1000)}>
          <Button variant="outline-success" size="lg">
            1000
          </Button>
        </Modaller>
        <br />
      </center>
    );
  }

  render() {
    if (this.props.ozelGunMu) {
      return (
        <div>
          {this.renderBaslik()}
          {this.renderKadirNum()}
          {this.renderDua()}
        </div>
      );
    }
    return (
      <div>
        {this.renderBaslik()}
        {this.renderKadirNum()}
        {this.renderSelector()}
      </div>
    );
  }
}

const mapStateToProps = ({ kadirNum, baslik, ozelGunMu, hatimDuasi }) => {
  return {
    kadirNum,
    baslik,
    ozelGunMu,
    hatimDuasi
  };
};

export default connect(mapStateToProps, actions)(KadirSuresi);
