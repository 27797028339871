import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../actions";
import Modaller from "./Modaller";
import { Button, FormGroup, FormControl, InputGroup, Alert } from "react-bootstrap";

class Admin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      baslik: "yükleniyor...",
      hatimduasi: "yükleniyor...",
      mirac: "yükleniyor...",
      berat: "yükleniyor...",
      regaip: "yükleniyor...",
      canakkale: "yükleniyor...",
      kadir: "yükleniyor...",
      kurban: "yükleniyor...",
      mevlid: "yükleniyor...",
      sifaDuasi: "yükleniyor...",
      rahmetDuasi: "yükleniyor...",
      salavat: "",
      istigfar: "",
      fatiha: "",
      fetih: "",
      ihlas: "",
      yasin: "",
      tevhid: "",
      hatim: "",
      kadirSuresi: "",
      firstFetch: true
    };
  }

  componentWillMount() {
    this.props.fetchDua();
    this.props.fetchBaslik();
    this.props.fetchMiracDua();
    this.props.fetchRegaipDua();
    this.props.fetchBeratDua();
    this.props.fetchMevlidDua();
    this.props.fetchCanakkaleDua();
    this.props.fetchKurbanDua();
    this.props.fetchKadirDua();
    this.props.fetchSifaDua();
    this.props.fetchRahmetDua();
    this.componentWillUpdate();
  }

  componentWillUpdate() {
    if (
      this.props.baslik !== this.state.baslik &&
      this.props.hatimDuasi &&
      this.props.mirac &&
      this.props.berat &&
      this.props.regaip &&
      this.props.mevlid &&
      this.props.kadir &&
      this.props.kurban &&
      this.props.canakkale &&
      this.props.rahmet &&
      this.props.sifa &&
      this.state.firstFetch
    )
      this.setState({
        baslik: this.props.baslik,
        hatimduasi: this.props.hatimDuasi,
        mirac: this.props.mirac,
        berat: this.props.berat,
        regaip: this.props.regaip,
        mevlid: this.props.mevlid,
        kadir: this.props.kadir,
        kurban: this.props.kurban,
        canakkale: this.props.canakkale,
        sifaDuasi: this.props.sifa,
        rahmetDuasi: this.props.rahmet,
        firstFetch: false
      });
  }

  validateForm() {
    return this.state.email.length > 0 && this.state.password.length > 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleSubmit = event => {
    event.preventDefault();
    this.setState({ email: "", password: "" });
    const { email, password } = this.state;
    if (email === "mehmet@demir.com") {
      if (password === "salih") {
        this.props.Login();
      }
    }
  };

  loginForm() {
    return (
      <div>
        <form onSubmit={this.handleSubmit}>
          <FormGroup controlId="email" bsSize="large">
            Email
            <FormControl
              autoFocus
              type="email"
              value={this.state.email}
              onChange={this.handleChange}
            />
          </FormGroup>
          <FormGroup controlId="password" bsSize="large">
            Şifre
            <FormControl value={this.state.password} onChange={this.handleChange} type="password" />
          </FormGroup>
          <Button block bsSize="large" disabled={!this.validateForm()} type="submit">
            Oturumu Aç
          </Button>
        </form>
      </div>
    );
  }

  adminPanel() {
    return (
      <div>
        <Alert key={"x"} variant={"success"}>
          Başarıyla giriş yaptınız.
        </Alert>
        <div>
          <FormGroup>
            Mübarek gün adı ve tarihi
            <FormControl
              as="textarea"
              rows="3"
              value={this.state.baslik}
              onChange={e => this.setState({ baslik: e.target.value })}
            />
          </FormGroup>
          <Button
            block
            bsSize="small"
            variant="success"
            type="submit"
            onClick={() => this.props.setBaslik(this.state.baslik)}
          >
            Kaydet
          </Button>
          <br />
          <hr />
          <FormGroup>
            Hatim Duası
            <FormControl
              as="textarea"
              rows="5"
              value={this.state.hatimduasi}
              onChange={e => this.setState({ hatimduasi: e.target.value })}
            />
          </FormGroup>
          <Button
            block
            bsSize="small"
            variant="success"
            type="submit"
            onClick={() => this.props.setDua(this.state.hatimduasi)}
          >
            Kaydet
          </Button>

          <br />
          <hr />
          <FormGroup>
            Berat Kandili Duası
            <FormControl
              as="textarea"
              rows="5"
              value={this.state.berat}
              onChange={e => this.setState({ berat: e.target.value })}
            />
          </FormGroup>
          <Button
            block
            bsSize="small"
            variant="success"
            type="submit"
            onClick={() => this.props.setBerat(this.state.berat)}
          >
            Kaydet
          </Button>
          <br />
          <hr />
          <FormGroup>
            Miraç Kandili Duası
            <FormControl
              as="textarea"
              rows="5"
              value={this.state.mirac}
              onChange={e => this.setState({ mirac: e.target.value })}
            />
          </FormGroup>
          <Button
            block
            bsSize="small"
            variant="success"
            type="submit"
            onClick={() => this.props.setMirac(this.state.mirac)}
          >
            Kaydet
          </Button>
          <br />
          <hr />
          <FormGroup>
            Mevlid Kandili Duası
            <FormControl
              as="textarea"
              rows="5"
              value={this.state.mevlid}
              onChange={e => this.setState({ mevlid: e.target.value })}
            />
          </FormGroup>
          <Button
            block
            bsSize="small"
            variant="success"
            type="submit"
            onClick={() => this.props.setMevlid(this.state.mevlid)}
          >
            Kaydet
          </Button>
          <br />
          <hr />
          <FormGroup>
            Kadir Gecesi Duası
            <FormControl
              as="textarea"
              rows="5"
              value={this.state.kadir}
              onChange={e => this.setState({ kadir: e.target.value })}
            />
          </FormGroup>
          <Button
            block
            bsSize="small"
            variant="success"
            type="submit"
            onClick={() => this.props.setKadir(this.state.kadir)}
          >
            Kaydet
          </Button>
          <br />
          <hr />
          <FormGroup>
            Kurban Bayramı Duası
            <FormControl
              as="textarea"
              rows="5"
              value={this.state.kurban}
              onChange={e => this.setState({ kurban: e.target.value })}
            />
          </FormGroup>
          <Button
            block
            bsSize="small"
            variant="success"
            type="submit"
            onClick={() => this.props.setKurban(this.state.kurban)}
          >
            Kaydet
          </Button>
          <br />
          <hr />
          <FormGroup>
            Çanakkale Şehitleri Duası
            <FormControl
              as="textarea"
              rows="5"
              value={this.state.canakkale}
              onChange={e => this.setState({ canakkale: e.target.value })}
            />
          </FormGroup>
          <Button
            block
            bsSize="small"
            variant="success"
            type="submit"
            onClick={() => this.props.setCanakkale(this.state.canakkale)}
          >
            Kaydet
          </Button>
          <br />
          <hr />
          <FormGroup>
            Regaip Kandili Duası
            <FormControl
              as="textarea"
              rows="5"
              value={this.state.regaip}
              onChange={e => this.setState({ regaip: e.target.value })}
            />
          </FormGroup>
          <Button
            block
            bsSize="small"
            variant="success"
            type="submit"
            onClick={() => this.props.setRegaip(this.state.regaip)}
          >
            Kaydet
          </Button>
          <br />
          <hr />
          <FormGroup>
            Şifa Duası
            <FormControl
              as="textarea"
              rows="5"
              value={this.state.sifaDuasi}
              onChange={e => this.setState({ sifaDuasi: e.target.value })}
            />
          </FormGroup>
          <Button
            block
            bsSize="small"
            variant="success"
            type="submit"
            onClick={() => this.props.setSifaDuasi(this.state.sifaDuasi)}
          >
            Kaydet
          </Button>
          <br />
          <hr />
          <FormGroup>
            Rahmet Duası
            <FormControl
              as="textarea"
              rows="5"
              value={this.state.rahmetDuasi}
              onChange={e => this.setState({ rahmetDuasi: e.target.value })}
            />
          </FormGroup>
          <Button
            block
            bsSize="small"
            variant="success"
            type="submit"
            onClick={() => this.props.setRahmetDuasi(this.state.rahmetDuasi)}
          >
            Kaydet
          </Button>

          <hr />
          <center>
            <InputGroup style={{ width: "40%", paddingTop: "10px" }}>
              <FormControl
                placeholder="Eklenecek hatim sayısı"
                value={this.state.hatim}
                onChange={e => this.setState({ hatim: e.target.value })}
              />
              <InputGroup.Append>
                <Button
                  variant="outline-secondary"
                  onClick={() => {
                    this.props.increaseHatim(this.state.hatim);
                    this.setState({ hatim: "" });
                  }}
                >
                  Ekle
                </Button>
              </InputGroup.Append>
            </InputGroup>
            <hr />
            <Button
              bsSize="small"
              variant="info"
              type="submit"
              onClick={() => this.props.showSalavatChange(true)}
            >
              Menüde salavatı göster.
            </Button>
            <text> </text>
            <Button
              variant="warning"
              bsSize="small"
              type="submit"
              onClick={() => this.props.showSalavatChange(false)}
            >
              Menüde salavatı gösterme.
            </Button>
            <text> </text>
            <InputGroup style={{ width: "40%", paddingTop: "10px" }}>
              <FormControl
                placeholder="Eklenecek salavat sayısı"
                value={this.state.salavat}
                onChange={e => this.setState({ salavat: e.target.value })}
              />
              <InputGroup.Append>
                <Button
                  variant="outline-secondary"
                  onClick={() => {
                    this.props.takeSalavat(this.state.salavat);
                    this.setState({ salavat: "" });
                  }}
                >
                  Ekle
                </Button>
              </InputGroup.Append>
            </InputGroup>
            <hr />
            <Button
              bsSize="small"
              variant="info"
              type="submit"
              onClick={() => this.props.showIstigfarChange(true)}
            >
              Menüde istiğfarı göster.
            </Button>
            <text> </text>
            <Button
              bsSize="small"
              variant="warning"
              type="submit"
              onClick={() => this.props.showIstigfarChange(false)}
            >
              Menüde istiğfarı gösterme.
            </Button>
            <text> </text>
            <InputGroup style={{ width: "40%", paddingTop: "10px" }}>
              <FormControl
                placeholder="Eklenecek istiğfar sayısı"
                value={this.state.istigfar}
                onChange={e => this.setState({ istigfar: e.target.value })}
              />
              <InputGroup.Append>
                <Button
                  variant="outline-secondary"
                  onClick={() => {
                    this.props.takeIstigfar(this.state.istigfar);
                    this.setState({ istigfar: "" });
                  }}
                >
                  Ekle
                </Button>
              </InputGroup.Append>
            </InputGroup>
            <hr />
            <Button
              bsSize="small"
              variant="info"
              type="submit"
              onClick={() => this.props.showFatihaChange(true)}
            >
              Menüde fatihayı göster.
            </Button>
            <text> </text>
            <Button
              bsSize="small"
              variant="warning"
              type="submit"
              onClick={() => this.props.showFatihaChange(false)}
            >
              Menüde fatihayı gösterme.
            </Button>
            <text> </text>
            <InputGroup style={{ width: "40%", paddingTop: "10px" }}>
              <FormControl
                placeholder="Eklenecek fatiha sayısı"
                value={this.state.fatiha}
                onChange={e => this.setState({ fatiha: e.target.value })}
              />
              <InputGroup.Append>
                <Button
                  variant="outline-secondary"
                  onClick={() => {
                    this.props.takeFatiha(this.state.fatiha);
                    this.setState({ fatiha: "" });
                  }}
                >
                  Ekle
                </Button>
              </InputGroup.Append>
            </InputGroup>

            <hr />
            <Button
              bsSize="small"
              variant="info"
              type="submit"
              onClick={() => this.props.showFetihChange(true)}
            >
              Menüde fetihi göster.
            </Button>
            <text> </text>
            <Button
              bsSize="small"
              variant="warning"
              type="submit"
              onClick={() => this.props.showFetihChange(false)}
            >
              Menüde fetihi gösterme.
            </Button>
            <text> </text>
            <InputGroup style={{ width: "40%", paddingTop: "10px" }}>
              <FormControl
                placeholder="Eklenecek fetih sayısı"
                value={this.state.fetih}
                onChange={e => this.setState({ fetih: e.target.value })}
              />
              <InputGroup.Append>
                <Button
                  variant="outline-secondary"
                  onClick={() => {
                    this.props.takeFetih(this.state.fetih);
                    this.setState({ fetih: "" });
                  }}
                >
                  Ekle
                </Button>
              </InputGroup.Append>
            </InputGroup>

            <hr />
            <Button
              bsSize="small"
              variant="info"
              type="submit"
              onClick={() => this.props.showIhlasChange(true)}
            >
              Menüde ihlası göster.
            </Button>
            <text> </text>
            <Button
              bsSize="small"
              variant="warning"
              type="submit"
              onClick={() => this.props.showIhlasChange(false)}
            >
              Menüde ihlası gösterme.
            </Button>
            <text> </text>
            <InputGroup style={{ width: "40%", paddingTop: "10px" }}>
              <FormControl
                placeholder="Eklenecek ihlas sayısı"
                value={this.state.ihlas}
                onChange={e => this.setState({ ihlas: e.target.value })}
              />
              <InputGroup.Append>
                <Button
                  variant="outline-secondary"
                  onClick={() => {
                    this.props.takeIhlas(this.state.ihlas);
                    this.setState({ ihlas: "" });
                  }}
                >
                  Ekle
                </Button>
              </InputGroup.Append>
            </InputGroup>

            <hr />
            <Button
              bsSize="small"
              variant="info"
              type="submit"
              onClick={() => this.props.showYasinChange(true)}
            >
              Menüde yasini göster.
            </Button>
            <text> </text>
            <Button
              bsSize="small"
              variant="warning"
              type="submit"
              onClick={() => this.props.showYasinChange(false)}
            >
              Menüde yasini gösterme.
            </Button>
            <text> </text>
            <InputGroup style={{ width: "40%", paddingTop: "10px" }}>
              <FormControl
                placeholder="Eklenecek yasin sayısı"
                value={this.state.yasin}
                onChange={e => this.setState({ yasin: e.target.value })}
              />
              <InputGroup.Append>
                <Button
                  variant="outline-secondary"
                  onClick={() => {
                    this.props.takeYasin(this.state.yasin);
                    this.setState({ yasin: "" });
                  }}
                >
                  Ekle
                </Button>
              </InputGroup.Append>
            </InputGroup>

            <hr />
            <Button
              bsSize="small"
              variant="info"
              type="submit"
              onClick={() => this.props.showKadirChange(true)}
            >
              Menüde kadir suresini göster.
            </Button>
            <text> </text>
            <Button
              bsSize="small"
              variant="warning"
              type="submit"
              onClick={() => this.props.showKadirChange(false)}
            >
              Menüde kadir suresini gösterme.
            </Button>
            <text> </text>
            <InputGroup style={{ width: "40%", paddingTop: "10px" }}>
              <FormControl
                placeholder="Eklenecek kadir suresi sayısı"
                value={this.state.kadirSuresi}
                onChange={e => this.setState({ kadirSuresi: e.target.value })}
              />
              <InputGroup.Append>
                <Button
                  variant="outline-secondary"
                  onClick={() => {
                    this.props.takeKadir(this.state.kadirSuresi);
                    this.setState({ kadirSuresi: "" });
                  }}
                >
                  Ekle
                </Button>
              </InputGroup.Append>
            </InputGroup>

            <hr />
            <Button
              bsSize="small"
              variant="info"
              type="submit"
              onClick={() => this.props.showTevhidChange(true)}
            >
              Menüde tevhidi göster.
            </Button>
            <text> </text>
            <Button
              bsSize="small"
              variant="warning"
              type="submit"
              onClick={() => this.props.showTevhidChange(false)}
            >
              Menüde tevhidi gösterme.
            </Button>
            <text> </text>
            <InputGroup style={{ width: "40%", paddingTop: "10px" }}>
              <FormControl
                placeholder="Eklenecek tevhid sayısı"
                value={this.state.tevhid}
                onChange={e => this.setState({ tevhid: e.target.value })}
              />
              <InputGroup.Append>
                <Button
                  variant="outline-secondary"
                  onClick={() => {
                    this.props.takeTevhid(this.state.tevhid);
                    this.setState({ tevhid: "" });
                  }}
                >
                  Ekle
                </Button>
              </InputGroup.Append>
            </InputGroup>

            <hr />
          </center>

          <Button block bsSize="small" type="submit" onClick={() => this.props.ozelGun(true)}>
            Hatim duasını göster.
          </Button>
          <Button
            block
            bsSize="small"
            type="submit"
            variant="secondary"
            onClick={() => this.props.ozelGun(false)}
          >
            Hatim duasını gösterme.
          </Button>
        </div>
        <hr />
        <div>
          <Button block bsSize="small" type="submit" onClick={() => this.props.cuzGoster(true)}>
            Cüzleri göster.
          </Button>
          <Button
            block
            bsSize="small"
            type="submit"
            variant="secondary"
            onClick={() => this.props.cuzGoster(false)}
          >
            Cüzleri gösterme.
          </Button>
        </div>
        <hr />
        <Modaller onPositiveClick={this.props.zero}>
          <Button block bsSize="large" type="submit" variant="danger">
            Tüm Bilgileri Sıfırla!
          </Button>
        </Modaller>
        <hr />
        <Button block bsSize="large" type="submit" variant="dark" onClick={this.props.Logout}>
          Oturumu Kapat
        </Button>
      </div>
    );
  }

  render() {
    if (this.props.auth) {
      return this.adminPanel();
    } else {
      return this.loginForm();
    }
  }
}

const mapStateToProps = ({
  auth,
  hatimDuasi,
  baslik,
  mirac,
  berat,
  regaip,
  mevlid,
  kurban,
  kadir,
  canakkale,
  sifa,
  rahmet
}) => {
  return {
    auth: auth,
    hatimDuasi: hatimDuasi,
    baslik,
    mirac,
    berat,
    regaip,
    mevlid,
    kurban,
    kadir,
    canakkale,
    sifa,
    rahmet
  };
};

export default connect(mapStateToProps, actions)(Admin);
