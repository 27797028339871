import React, { Component } from "react";
import "../style.css";
import Hatim from "./Hatim";
import Salavat from "./Salavat";
import Tevhid from "./Tevhid";
import Istigfar from "./Istigfar";
import Dualar from "./Dualar";
import Admin from "./Admin";
import Fatiha from "./Fatiha";
import Yasin from "./Yasin";
import Ihlas from "./Ihlas";
import Fetih from "./Fetih";
import KadirSuresi from "./KadirSuresi";

import { connect } from "react-redux";
import * as actions from "../actions";

import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

class App extends Component {
  constructor() {
    super();
    this.state = {
      currentJob: "hatim"
    };
    this.tabbingHatim = this.tabbingHatim.bind(this);
    this.tabbingSalavat = this.tabbingSalavat.bind(this);
    this.tabbingIstigfar = this.tabbingIstigfar.bind(this);
    this.tabbingTevhid = this.tabbingTevhid.bind(this);
    this.tabbingDualar = this.tabbingDualar.bind(this);
    this.tabbingFatiha = this.tabbingFatiha.bind(this);
    this.tabbingFetih = this.tabbingFetih.bind(this);
    this.tabbingYasin = this.tabbingYasin.bind(this);
    this.tabbingIhlas = this.tabbingIhlas.bind(this);
  }
  componentWillMount() {
    this.props.fetchShowSalavat();
    this.props.fetchShowIstigfar();
    this.props.fetchShowTevhid();
    this.props.fetchShowFatiha();
    this.props.fetchShowFetih();
    this.props.fetchShowYasin();
    this.props.fetchShowIhlas();
    this.props.fetchShowKadir();
  }

  tabbingHatim() {
    this.setState({
      currentJob: "hatim"
    });
  }
  tabbingSalavat() {
    this.setState({
      currentJob: "salavat"
    });
  }
  tabbingIstigfar() {
    this.setState({
      currentJob: "istigfar"
    });
  }
  tabbingTevhid() {
    this.setState({
      currentJob: "tevhid"
    });
  }
  tabbingDualar() {
    this.setState({
      currentJob: "dualar"
    });
  }
  tabbingFatiha() {
    this.setState({
      currentJob: "fatiha"
    });
  }
  tabbingFetih() {
    this.setState({
      currentJob: "fetih"
    });
  }
  tabbingYasin() {
    this.setState({
      currentJob: "yasin"
    });
  }
  tabbingIhlas() {
    this.setState({
      currentJob: "ihlas"
    });
  }
  tabbingKadir = () => {
    this.setState({ currentJob: "kadir" });
  };
  pageShower() {
    const { currentJob } = this.state;
    if (currentJob === "hatim") {
      return <Hatim />;
    } else if (currentJob === "salavat") {
      return <Salavat />;
    } else if (currentJob === "istigfar") {
      return <Istigfar />;
    } else if (currentJob === "tevhid") {
      return <Tevhid />;
    } else if (currentJob === "dualar") {
      return <Dualar />;
    } else if (currentJob === "admin") {
      return <Admin />;
    } else if (currentJob === "fatiha") {
      return <Fatiha />;
    } else if (currentJob === "yasin") {
      return <Yasin />;
    } else if (currentJob === "ihlas") {
      return <Ihlas />;
    } else if (currentJob === "fetih") {
      return <Fetih />;
    } else if (currentJob === "kadir") {
      return <KadirSuresi />;
    } else {
      return <Hatim />;
    }
  }

  render() {
    return (
      <div>
        <div>
          <Navbar bg="dark" sticky="top" variant="dark" expand="lg">
            <Nav className="justify-content-center" style={{ width: "100%" }} activeKey="/home">
              <Nav.Link onClick={this.tabbingHatim} style={{ fontSize: "23px" }}>
                HATİM
              </Nav.Link>
              <Nav.Link
                onClick={this.tabbingSalavat}
                style={{
                  fontSize: "23px",
                  display: this.props.showSalavat ? "inline" : "none"
                }}
              >
                SALAVAT
              </Nav.Link>
              <Nav.Link
                onClick={this.tabbingIstigfar}
                style={{
                  fontSize: "23px",
                  display: this.props.showIstigfar ? "inline" : "none"
                }}
              >
                İSTİĞFAR
              </Nav.Link>
              <Nav.Link
                onClick={this.tabbingFatiha}
                style={{
                  fontSize: "23px",
                  display: this.props.showFatiha ? "inline" : "none"
                }}
              >
                FATİHA
              </Nav.Link>
              <Nav.Link
                onClick={this.tabbingFetih}
                style={{
                  fontSize: "23px",
                  display: this.props.showFetih ? "inline" : "none"
                }}
              >
                FETİH
              </Nav.Link>
              <Nav.Link
                onClick={this.tabbingIhlas}
                style={{
                  fontSize: "23px",
                  display: this.props.showIhlas ? "inline" : "none"
                }}
              >
                İHLAS
              </Nav.Link>
              <Nav.Link
                onClick={this.tabbingYasin}
                style={{
                  fontSize: "23px",
                  display: this.props.showYasin ? "inline" : "none"
                }}
              >
                YASİN
              </Nav.Link>
              <Nav.Link
                onClick={this.tabbingTevhid}
                style={{
                  fontSize: "23px",
                  display: this.props.showTevhid ? "inline" : "none"
                }}
              >
                TEVHİD
              </Nav.Link>
              <Nav.Link
                onClick={this.tabbingKadir}
                style={{
                  fontSize: "23px",
                  display: this.props.showKadir ? "inline" : "none"
                }}
              >
                KADİR SURESİ
              </Nav.Link>
              <Nav.Link onClick={this.tabbingDualar} style={{ fontSize: "23px" }}>
                HATİM DUALARI
              </Nav.Link>
              <Nav.Link onClick={() => this.setState({ currentJob: "admin" })} style={{ fontSize: "23px" }}>
                YÖNETİCİ
              </Nav.Link>
            </Nav>
          </Navbar>
        </div>
        <div>{this.pageShower()}</div>
      </div>
    );
  }
}

const mapStateToProps = ({
  showFatiha,
  showFetih,
  showSalavat,
  showTevhid,
  showIstigfar,
  showYasin,
  showIhlas,
  showKadir
}) => {
  return {
    showFatiha,
    showFetih,
    showSalavat,
    showTevhid,
    showIstigfar,
    showYasin,
    showIhlas,
    showKadir
  };
};

export default connect(mapStateToProps, actions)(App);
