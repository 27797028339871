import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import Modaller from "./Modaller";
import { Button } from "react-bootstrap";
import * as actions from "../actions";
import Cuz from "./Cuz";
import "./App.css";

class Hatim extends Component {
  inputChange = event => {
    this.setState({ formValue: event.target.value });
  };

  renderExplanation() {
    return (
      <center>
        <Button variant="dark" style={{ height: 80, width: 200 }} active>
          <text style={{ fontSize: 25 }}>↓ Cüz Al ↓</text>
        </Button>
        <h5 style={{ color: "red" }}>Kırmızı cüzler alınmıştır,</h5>
        <h5 style={{ color: "#128432" }}>Yeşil cüzler ise müsaittir.</h5>
        <h6>Tüm cüzler alındığında hepsi tekrardan müsait duruma getirilir.</h6>
      </center>
    );
  }
  renderToDo() {
    const { data } = this.props;
    const toDos = _.map(data, (value, key) => {
      return <Cuz key={key} todoId={key} todo={value} />;
    });
    if (!_.isEmpty(toDos)) {
      return toDos;
    }
  }

  renderTakeWholeHatim() {
    return (
      <center>
        <Modaller onPositiveClick={this.props.takeWholeHatim}>
          <Button style={{ height: 80, width: 200 }}>
            <text style={{ fontSize: 25 }}>Tam Hatim Al</text>
          </Button>
        </Modaller>
        <br />
      </center>
    );
  }

  takeWholeHatim = () => {
    this.props.takeWholeHatim();
  };

  renderHatimNum() {
    const { hatimNum } = this.props;
    if (isNaN(hatimNum)) {
      return (
        <center>
          <h3>Yükleniyor...</h3>
        </center>
      );
    }
    let hatim_num = Math.floor(hatimNum / 30);
    return (
      <div>
        <center>
          <h3>Toplam alınmış hatim sayısı: {hatim_num}</h3>
        </center>
      </div>
    );
  }

  componentWillMount() {
    this.props.fetchToDos();
    this.props.fetchHatimNum();
    this.props.fetchOzelGun();
    this.props.fetchHatimGoster();
    this.props.fetchDua();
    this.props.fetchBaslik();
  }

  componentDidUpdate() {
    const { hatimNum } = this.props;
    if (hatimNum % 30 === 0 && hatimNum !== 0) {
      this.props.hatimDone();
    }
  }

  renderBaslik() {
    let newText = this.props.baslik
      .split("\n")
      .map((item, i) => <p key={i}>{item}</p>);
    return (
      <center>
        <hr />
        <h2>{newText}</h2>
        <hr />
      </center>
    );
  }

  renderDua() {
    let newText = this.props.hatimDuasi
      .split("\n")
      .map((item, i) => <p key={i}>{item}</p>);

    return (
      <center>
        <hr />
        <h4>
          Hatim Duası:
          <br />
          <br />
          {newText}
          <br />
          <br />
        </h4>
      </center>
    );
  }

  render() {
    const { ozelGunMu, hatimGoster } = this.props;
    if (hatimGoster && ozelGunMu) {
      return (
        <div>
          {this.renderBaslik()}
          {this.renderHatimNum()}
          {this.renderDua()}
          {this.renderTakeWholeHatim()}
          {this.renderExplanation()}
          {this.renderToDo()}
        </div>
      );
    }
    if (ozelGunMu) {
      return (
        <div>
          {this.renderBaslik()}
          {this.renderHatimNum()}
          {this.renderDua()}
        </div>
      );
    }
    if (hatimGoster) {
      return (
        <div>
          {this.renderBaslik()}
          {this.renderHatimNum()}
          {this.renderTakeWholeHatim()}
          {this.renderExplanation()}
          {this.renderToDo()}
        </div>
      );
    } else {
      return this.renderBaslik();
    }
  }
}

const mapStateToProps = ({
  data,
  hatimNum,
  ozelGunMu,
  hatimGoster,
  hatimDuasi,
  baslik
}) => {
  return {
    data,
    hatimNum,
    ozelGunMu,
    hatimGoster,
    hatimDuasi,
    baslik
  };
};

export default connect(
  mapStateToProps,
  actions
)(Hatim);
