import React, { Component } from "react";
import Modal from "react-responsive-modal";
import { Button } from "react-bootstrap";

export default class Modaller extends Component {
  state = {
    open: false
  };

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  onClickModal = () => {
    this.props.onPositiveClick();
    this.onCloseModal();
  };

  render() {
    const { open } = this.state;
    return (
      <div>
        <span onClick={this.onOpenModal}>{this.props.children}</span>
        <Modal open={open} onClose={this.onCloseModal} center>
          <h2>Emin misiniz?</h2>
          <p>
            Yapacağınız işlem kayıtlara düşecektir, geri alınamaz. Emin misiniz?
          </p>
          <div>
            <center>
              <span onClick={this.onClickModal}>
                <Button key="success" variant="warning">
                  Evet, eminim.
                </Button>
              </span>
              <text> </text>
              <span onClick={this.onCloseModal}>
                <Button key="fail" variant="secondary">
                  Hayır, iptal et.
                </Button>
              </span>
            </center>
          </div>
        </Modal>
      </div>
    );
  }
}
