import React, { Component } from "react";
import { Accordion, Button } from "react-bootstrap";
import { connect } from "react-redux";
import * as actions from "../actions";

class Dualar extends Component {
  componentWillMount() {
    this.props.fetchMiracDua();
    this.props.fetchRegaipDua();
    this.props.fetchBeratDua();
    this.props.fetchBaslik();
    this.props.fetchDua();
    this.props.fetchOzelGun();
    this.props.fetchMevlidDua();
    this.props.fetchCanakkaleDua();
    this.props.fetchKurbanDua();
    this.props.fetchKadirDua();
    this.props.fetchSifaDua();
    this.props.fetchRahmetDua();
  }

  renderBaslik() {
    let newText = this.props.baslik.split("\n").map((item, i) => <p key={i}>{item}</p>);
    return (
      <center>
        <hr />
        <h2>{newText}</h2>
        <hr />
      </center>
    );
  }

  renderDua() {
    let hatimDuasi = this.props.hatimDuasi.split("\n").map((item, i) => <p key={i}>{item}</p>);
    let regaipDuasi = this.props.regaip.split("\n").map((item, i) => <p key={i}>{item}</p>);
    let miracDuasi = this.props.mirac.split("\n").map((item, i) => <p key={i}>{item}</p>);
    let beratDuasi = this.props.berat.split("\n").map((item, i) => <p key={i}>{item}</p>);
    let mevlidDuasi = this.props.mevlid.split("\n").map((item, i) => <p key={i}>{item}</p>);
    let kadirDuasi = this.props.kadir.split("\n").map((item, i) => <p key={i}>{item}</p>);
    let kurbanDuasi = this.props.kurban.split("\n").map((item, i) => <p key={i}>{item}</p>);
    let canakkaleDuasi = this.props.canakkale.split("\n").map((item, i) => <p key={i}>{item}</p>);
    let sifaDuasi = this.props.sifa.split("\n").map((item, i) => <p key={i}>{item}</p>);
    let rahmetDuasi = this.props.rahmet.split("\n").map((item, i) => <p key={i}>{item}</p>);
    return (
      <center>
        <div>
          <Accordion>
            <Accordion.Toggle as={Button} eventKey="0">
              <Button block bsSize="large" type="submit">
                Güncel Hatim Duası
              </Button>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <text>{hatimDuasi}</text>
            </Accordion.Collapse>
            <br />
            <br />

            <Accordion.Toggle as={Button} eventKey="1">
              <Button block bsSize="large" type="submit">
                Regaip Kandili Duası
              </Button>
            </Accordion.Toggle>
            <br />
            <br />
            <Accordion.Collapse eventKey="1">
              <text>{regaipDuasi}</text>
            </Accordion.Collapse>

            <Accordion.Toggle as={Button} eventKey="2">
              <Button block bsSize="large" type="submit">
                Miraç Kandili Duası
              </Button>
            </Accordion.Toggle>
            <br />
            <br />
            <Accordion.Collapse eventKey="2">
              <text>{miracDuasi}</text>
            </Accordion.Collapse>

            <Accordion.Toggle as={Button} eventKey="3">
              <Button block bsSize="large" type="submit">
                Berat Kandili Duası
              </Button>
            </Accordion.Toggle>
            <br />
            <br />
            <Accordion.Collapse eventKey="3">
              <text>{beratDuasi}</text>
            </Accordion.Collapse>

            <Accordion.Toggle as={Button} eventKey="4">
              <Button block bsSize="large" type="submit">
                Mevlid Kandili Duası
              </Button>
            </Accordion.Toggle>
            <br />
            <br />
            <Accordion.Collapse eventKey="4">
              <text>{mevlidDuasi}</text>
            </Accordion.Collapse>

            <Accordion.Toggle as={Button} eventKey="5">
              <Button block bsSize="large" type="submit">
                Kadir Gecesi Duası
              </Button>
            </Accordion.Toggle>
            <br />
            <br />
            <Accordion.Collapse eventKey="5">
              <text>{kadirDuasi}</text>
            </Accordion.Collapse>

            <Accordion.Toggle as={Button} eventKey="6">
              <Button block bsSize="large" type="submit">
                Kurban Bayramı Duası
              </Button>
            </Accordion.Toggle>
            <br />
            <br />
            <Accordion.Collapse eventKey="6">
              <text>{kurbanDuasi}</text>
            </Accordion.Collapse>

            <Accordion.Toggle as={Button} eventKey="7">
              <Button block bsSize="large" type="submit">
                Çanakkale Şehitleri Duası
              </Button>
            </Accordion.Toggle>
            <br />
            <br />
            <Accordion.Collapse eventKey="7">
              <text>{canakkaleDuasi}</text>
            </Accordion.Collapse>

            <Accordion.Toggle as={Button} eventKey="88">
              <Button block bsSize="large" type="submit">
                Rahmet Duası
              </Button>
            </Accordion.Toggle>
            <br />
            <br />
            <Accordion.Collapse eventKey="88">
              <text>{rahmetDuasi}</text>
            </Accordion.Collapse>

            <Accordion.Toggle as={Button} eventKey="99">
              <Button block bsSize="large" type="submit">
                Şifa Duası
              </Button>
            </Accordion.Toggle>
            <br />
            <br />
            <Accordion.Collapse eventKey="99">
              <text>{sifaDuasi}</text>
            </Accordion.Collapse>

            {/* let them be inside accordion tags */}
          </Accordion>
        </div>
      </center>
    );
  }

  render() {
    return (
      <div>
        {this.renderBaslik()}
        {this.renderDua()}
      </div>
    );
  }
}

const mapStateToProps = ({
  baslik,
  hatimDuasi,
  mirac,
  regaip,
  berat,
  mevlid,
  kadir,
  kurban,
  canakkale,
  sifa,
  rahmet
}) => {
  return {
    baslik,
    hatimDuasi,
    mirac,
    regaip,
    berat,
    mevlid,
    kadir,
    kurban,
    canakkale,
    sifa,
    rahmet
  };
};

export default connect(mapStateToProps, actions)(Dualar);
