import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../actions";
import Modaller from "./Modaller";
import Button from "react-bootstrap/Button";

class Istigfar extends Component {
  componentWillMount() {
    this.props.fetchIstigfarNum();
    this.props.fetchBaslik();
    this.props.fetchDua();
    this.props.fetchOzelGun();
  }
  renderIstigfarNum() {
    const { istigfarNum } = this.props;
    if (isNaN(istigfarNum)) {
      return (
        <center>
          <h2>Yükleniyor...</h2>
        </center>
      );
    }
    return (
      <center>
        <h3>Toplam alınmış istiğfar sayısı: {istigfarNum}</h3>
        <h4 style={{ color: "blue" }}>
          Almak istediğiniz miktarın üstüne tıklayarak alabilirsiniz.
        </h4>
        <hr />
      </center>
    );
  }

  renderBaslik() {
    let newText = this.props.baslik
      .split("\n")
      .map((item, i) => <p key={i}>{item}</p>);
    return (
      <center>
        <hr />
        <h2>{newText}</h2>
        <hr />
      </center>
    );
  }

  renderDua() {
    let newText = this.props.hatimDuasi
      .split("\n")
      .map((item, i) => <p key={i}>{item}</p>);

    return (
      <center>
        <hr />
        <h4>
          Hatim Duası:
          <br />
          <br />
          {newText}
          <br />
          <br />
        </h4>
      </center>
    );
  }

  renderSelector() {
    return (
      <center>
        <Modaller onPositiveClick={() => this.props.takeIstigfar(1000)}>
          <Button variant="outline-success" size="lg">
            1000
          </Button>
        </Modaller>
        <br />
        <Modaller onPositiveClick={() => this.props.takeIstigfar(5000)}>
          <Button variant="outline-success" size="lg">
            5000
          </Button>
        </Modaller>
        <br />
        <Modaller onPositiveClick={() => this.props.takeIstigfar(10000)}>
          <Button variant="outline-success" size="lg">
            10.000
          </Button>
        </Modaller>
        <br />
        <Modaller onPositiveClick={() => this.props.takeIstigfar(20000)}>
          <Button variant="outline-success" size="lg">
            20.000
          </Button>
        </Modaller>
        <br />
        <Modaller onPositiveClick={() => this.props.takeIstigfar(70000)}>
          <Button variant="outline-success" size="lg">
            70.000
          </Button>
        </Modaller>
        <br />
        <Modaller onPositiveClick={() => this.props.takeIstigfar(100000)}>
          <Button variant="outline-success" size="lg">
            100.000
          </Button>
        </Modaller>
        <br />
      </center>
    );
  }

  render() {
    if (this.props.ozelGunMu) {
      return (
        <div>
          {this.renderBaslik()}
          {this.renderIstigfarNum()}
          {this.renderDua()}
        </div>
      );
    }
    return (
      <div>
        {this.renderBaslik()}
        {this.renderIstigfarNum()}
        {this.renderSelector()}
      </div>
    );
  }
}

const mapStateToProps = ({ istigfarNum, baslik, ozelGunMu, hatimDuasi }) => {
  return {
    istigfarNum,
    baslik,
    ozelGunMu,
    hatimDuasi
  };
};

export default connect(
  mapStateToProps,
  actions
)(Istigfar);
