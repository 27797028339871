import {
  hatimRef,
  cuzNumRef,
  salavatNumRef,
  baslikRef,
  hatimGosterRef,
  duaRef,
  tevhidNumRef,
  istigfarNumRef,
  beratDuasi,
  miracDuasi,
  regaipDuasi,
  fatihaNumRef,
  fetihNumRef,
  yasinNumRef,
  ihlasNumRef,
  showSalavatRef,
  showFetihRef,
  showTevhidRef,
  showIstigfarRef,
  showIhlasRef,
  showYasinRef,
  showFatihaRef,
  kadirDuasi,
  kurbanDuasi,
  canakkaleDuasi,
  mevlidDuasi,
  kadirNumRef,
  showKadirRef,
  sifaDuasi,
  rahmetDuasi
} from "../config/firebase";
import {
  FETCH_TODOS,
  FETCH_HATIM_NUM,
  FETCH_SALAVAT_NUM,
  LOG_IN,
  LOG_OUT,
  FETCH_MIRAC,
  FETCH_REGAIP,
  FETCH_BERAT
} from "./types";

export const completeToDo = completeToDoId => async dispatch => {
  hatimRef.child(completeToDoId + "/isTaken").transaction(function() {
    return true;
  });
  cuzNumRef.transaction(function(cuzNum) {
    let temp = cuzNum + 1;
    return temp;
  });
};

export const takeSalavat = numOfSalavat => async dispatch => {
  salavatNumRef.transaction(function(salavatNum) {
    let temp = salavatNum + parseInt(numOfSalavat);
    if (!isNaN(temp)) return temp;
  });
};
export const takeTevhid = numOfSalavat => async dispatch => {
  tevhidNumRef.transaction(function(salavatNum) {
    let temp = salavatNum + parseInt(numOfSalavat);
    if (!isNaN(temp)) return temp;
  });
};
export const takeIstigfar = numOfSalavat => async dispatch => {
  istigfarNumRef.transaction(function(salavatNum) {
    let temp = salavatNum + parseInt(numOfSalavat);
    if (!isNaN(temp)) return temp;
  });
};
export const takeFatiha = numOfSalavat => async dispatch => {
  fatihaNumRef.transaction(function(salavatNum) {
    let temp = salavatNum + parseInt(numOfSalavat);
    if (!isNaN(temp)) return temp;
  });
};
export const takeFetih = numOfSalavat => async dispatch => {
  fetihNumRef.transaction(function(salavatNum) {
    let temp = salavatNum + parseInt(numOfSalavat);
    if (!isNaN(temp)) return temp;
  });
};
export const takeYasin = numOfSalavat => async dispatch => {
  yasinNumRef.transaction(function(salavatNum) {
    let temp = salavatNum + parseInt(numOfSalavat);
    if (!isNaN(temp)) return temp;
  });
};
export const takeKadir = numOfSalavat => async dispatch => {
  kadirNumRef.transaction(salavatNum => {
    let temp = salavatNum + parseInt(numOfSalavat);
    if (!isNaN(temp)) return temp;
  });
};
export const takeIhlas = numOfSalavat => async dispatch => {
  ihlasNumRef.transaction(function(salavatNum) {
    let temp = salavatNum + parseInt(numOfSalavat);
    if (!isNaN(temp)) return temp;
  });
};
export const takeWholeHatim = () => async dispatch => {
  return cuzNumRef.transaction(function(cuzNum) {
    let temp = cuzNum + 30;
    return temp;
  });
};

export const hatimDone = () => async dispatch => {
  return hatimRef.once("value", function(snapshot) {
    snapshot.forEach(function(child) {
      child.ref.update({
        isTaken: false
      });
    });
  });
};

export const fetchToDos = () => async dispatch => {
  hatimRef.on("value", snapshot => {
    dispatch({
      type: FETCH_TODOS,
      payload: snapshot.val()
    });
  });
};

export const fetchHatimNum = () => async dispatch => {
  cuzNumRef.on("value", snapshot => {
    dispatch({
      type: FETCH_HATIM_NUM,
      payload: snapshot.val()
    });
  });
};

export const fetchSalavatNum = () => async dispatch => {
  salavatNumRef.on("value", snapshot => {
    dispatch({
      type: FETCH_SALAVAT_NUM,
      payload: snapshot.val()
    });
  });
};
export const fetchTevhidNum = () => async dispatch => {
  tevhidNumRef.on("value", snapshot => {
    dispatch({
      type: "TEVHID",
      payload: snapshot.val()
    });
  });
};
export const fetchIstigfarNum = () => async dispatch => {
  istigfarNumRef.on("value", snapshot => {
    dispatch({
      type: "ISTIGFAR",
      payload: snapshot.val()
    });
  });
};
export const fetchFatihaNum = () => async dispatch => {
  fatihaNumRef.on("value", snapshot => {
    dispatch({
      type: "FATIHA",
      payload: snapshot.val()
    });
  });
};
export const fetchFetihNum = () => async dispatch => {
  fetihNumRef.on("value", snapshot => {
    dispatch({
      type: "FETIH",
      payload: snapshot.val()
    });
  });
};
export const fetchIhlasNum = () => async dispatch => {
  ihlasNumRef.on("value", snapshot => {
    dispatch({
      type: "IHLAS",
      payload: snapshot.val()
    });
  });
};
export const fetchYasinNum = () => async dispatch => {
  yasinNumRef.on("value", snapshot => {
    dispatch({
      type: "YASIN",
      payload: snapshot.val()
    });
  });
};
export const fetchKadirNum = () => async dispatch => {
  kadirNumRef.on("value", snapshot => {
    dispatch({
      type: "KADIR_NUM",
      payload: snapshot.val()
    });
  });
};
export const fetchMiracDua = () => async dispatch => {
  miracDuasi.on("value", snapshot => {
    dispatch({
      type: FETCH_MIRAC,
      payload: snapshot.val()
    });
  });
};
export const fetchRegaipDua = () => async dispatch => {
  regaipDuasi.on("value", snapshot => {
    dispatch({
      type: FETCH_REGAIP,
      payload: snapshot.val()
    });
  });
};
export const fetchMevlidDua = () => async dispatch => {
  mevlidDuasi.on("value", snapshot => {
    dispatch({
      type: "FETCH_MEVLID",
      payload: snapshot.val()
    });
  });
};
export const fetchKadirDua = () => async dispatch => {
  kadirDuasi.on("value", snapshot => {
    dispatch({
      type: "FETCH_KADIR",
      payload: snapshot.val()
    });
  });
};
export const fetchKurbanDua = () => async dispatch => {
  kurbanDuasi.on("value", snapshot => {
    dispatch({
      type: "FETCH_KURBAN",
      payload: snapshot.val()
    });
  });
};
export const fetchCanakkaleDua = () => async dispatch => {
  canakkaleDuasi.on("value", snapshot => {
    dispatch({
      type: "FETCH_CANAKKALE",
      payload: snapshot.val()
    });
  });
};
export const fetchBeratDua = () => async dispatch => {
  beratDuasi.on("value", snapshot => {
    dispatch({
      type: FETCH_BERAT,
      payload: snapshot.val()
    });
  });
};
export const fetchSifaDua = () => async dispatch => {
  sifaDuasi.on("value", snapshot => {
    dispatch({
      type: "FETCH_SIFA_DUASI",
      payload: snapshot.val()
    });
  });
};
export const fetchRahmetDua = () => async dispatch => {
  rahmetDuasi.on("value", snapshot => {
    dispatch({
      type: "FETCH_RAHMET_DUASI",
      payload: snapshot.val()
    });
  });
};
export const setMirac = dua => async dispatch => {
  miracDuasi.transaction(function() {
    return dua;
  });
};
export const setRegaip = dua => async dispatch => {
  regaipDuasi.transaction(function() {
    return dua;
  });
};
export const setBerat = dua => async dispatch => {
  beratDuasi.transaction(function() {
    return dua;
  });
};
export const setKurban = dua => async dispatch => {
  kurbanDuasi.transaction(function() {
    return dua;
  });
};
export const setKadir = dua => async dispatch => {
  kadirDuasi.transaction(function() {
    return dua;
  });
};
export const setMevlid = dua => async dispatch => {
  mevlidDuasi.transaction(function() {
    return dua;
  });
};
export const setCanakkale = dua => async dispatch => {
  canakkaleDuasi.transaction(function() {
    return dua;
  });
};
export const setSifaDuasi = dua => async dispatch => {
  sifaDuasi.transaction(function() {
    return dua;
  });
};
export const setRahmetDuasi = dua => async dispatch => {
  rahmetDuasi.transaction(function() {
    return dua;
  });
};

export const Login = loggedIn => {
  return {
    type: LOG_IN,
    payload: true
  };
};

export const Logout = () => {
  return {
    type: LOG_OUT,
    payload: false
  };
};

export const ozelGun = changeIt => async dispatch => {
  duaRef.child("goster").transaction(function() {
    return changeIt;
  });
};

export const setDua = dua => async dispatch => {
  duaRef.child("title").transaction(function() {
    return dua;
  });
};

export const setBaslik = baslik => async dispatch => {
  baslikRef.transaction(function() {
    return baslik;
  });
};

export const zero = () => async dispatch => {
  cuzNumRef.transaction(function() {
    return 0;
  });
  salavatNumRef.transaction(function() {
    return 0;
  });
  tevhidNumRef.transaction(function() {
    return 0;
  });
  istigfarNumRef.transaction(function() {
    return 0;
  });
  fatihaNumRef.transaction(function() {
    return 0;
  });
  yasinNumRef.transaction(function() {
    return 0;
  });
  fetihNumRef.transaction(function() {
    return 0;
  });
  ihlasNumRef.transaction(function() {
    return 0;
  });
  kadirNumRef.transaction(() => 0);
  return hatimRef.once("value", function(snapshot) {
    snapshot.forEach(child => {
      child.ref.update({
        isTaken: false
      });
    });
  });
};

export const fetchDua = () => async dispatch => {
  duaRef.child("title").on("value", snapshot => {
    dispatch({
      type: "FETCH_DUA",
      payload: snapshot.val()
    });
  });
};

export const fetchBaslik = () => async dispatch => {
  baslikRef.on("value", snapshot => {
    dispatch({
      type: "FETCH_BASLIK",
      payload: snapshot.val()
    });
  });
};

export const cuzGoster = changeIt => async dispatch => {
  hatimGosterRef.transaction(function() {
    return changeIt;
  });
};

export const showTevhidChange = changeIt => async dispatch => {
  showTevhidRef.transaction(function() {
    return changeIt;
  });
};
export const showSalavatChange = changeIt => async dispatch => {
  showSalavatRef.transaction(function() {
    return changeIt;
  });
};
export const showIstigfarChange = changeIt => async dispatch => {
  showIstigfarRef.transaction(function() {
    return changeIt;
  });
};
export const showFetihChange = changeIt => async dispatch => {
  showFetihRef.transaction(function() {
    return changeIt;
  });
};
export const showFatihaChange = changeIt => async dispatch => {
  showFatihaRef.transaction(function() {
    return changeIt;
  });
};
export const showYasinChange = changeIt => async dispatch => {
  showYasinRef.transaction(function() {
    return changeIt;
  });
};
export const showIhlasChange = changeIt => async dispatch => {
  showIhlasRef.transaction(function() {
    return changeIt;
  });
};
export const showKadirChange = changeIt => {
  showKadirRef.transaction(() => changeIt);
};

export const fetchOzelGun = () => async dispatch => {
  duaRef.child("goster").on("value", snapshot => {
    dispatch({
      type: "FETCH_OZEL_GUN",
      payload: snapshot.val()
    });
  });
};
export const fetchShowSalavat = () => async dispatch => {
  showSalavatRef.on("value", snapshot => {
    dispatch({
      type: "SHOW_SALAVAT",
      payload: snapshot.val()
    });
  });
};
export const fetchShowIstigfar = () => async dispatch => {
  showIstigfarRef.on("value", snapshot => {
    dispatch({
      type: "SHOW_ISTIGFAR",
      payload: snapshot.val()
    });
  });
};
export const fetchShowTevhid = () => async dispatch => {
  showTevhidRef.on("value", snapshot => {
    dispatch({
      type: "SHOW_TEVHID",
      payload: snapshot.val()
    });
  });
};
export const fetchShowFatiha = () => async dispatch => {
  showFatihaRef.on("value", snapshot => {
    dispatch({
      type: "SHOW_FATIHA",
      payload: snapshot.val()
    });
  });
};
export const fetchShowFetih = () => async dispatch => {
  showFetihRef.on("value", snapshot => {
    dispatch({
      type: "SHOW_FETIH",
      payload: snapshot.val()
    });
  });
};
export const fetchShowYasin = () => async dispatch => {
  showYasinRef.on("value", snapshot => {
    dispatch({
      type: "SHOW_YASIN",
      payload: snapshot.val()
    });
  });
};
export const fetchShowIhlas = () => async dispatch => {
  showIhlasRef.on("value", snapshot => {
    dispatch({
      type: "SHOW_IHLAS",
      payload: snapshot.val()
    });
  });
};
export const fetchShowKadir = () => async dispatch => {
  showKadirRef.on("value", snapshot => {
    dispatch({ type: "SHOW_KADIR", payload: snapshot.val() });
  });
};

export const fetchHatimGoster = () => async dispatch => {
  hatimGosterRef.on("value", snapshot => {
    dispatch({
      type: "FETCH_HATIM_GOSTER",
      payload: snapshot.val()
    });
  });
};

export const increaseHatim = num => async dispatch => {
  cuzNumRef.transaction(function(cuzNum) {
    let temp = cuzNum + parseInt(num) * 30;
    if (!isNaN(temp)) return temp;
  });
};
