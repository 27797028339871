import React, { Component } from "react";
import { connect } from "react-redux";
import Modaller from "./Modaller";
import * as actions from "../actions";
import "./App.css";

class Cuz extends Component {
  completeClick = completeTodoId => {
    const { completeToDo } = this.props;
    completeToDo(completeTodoId);
  };

  render() {
    const { todoId, todo } = this.props;
    if (!todo.isTaken) {
      return (
        <div key={todoId} style={{ cursor: "pointer" }}>
          <Modaller onPositiveClick={this.completeClick.bind(this, todoId)}>
            <div className="card">
              <span className="cuzText">Cüz {todo.title}</span>
              <span className="cuzText">(Boş)</span>
            </div>
          </Modaller>
        </div>
      );
    } else {
      return (
        <div key={todoId}>
          <div className="cardTaken">
            <span className="cuzText">
              Cüz {todo.title}
              <br />
              (Alındı)
            </span>
          </div>
        </div>
      );
    }
  }
}

export default connect(
  null,
  actions
)(Cuz);
