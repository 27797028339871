import React, { Component } from 'react';
import MainApp from './components/App';

class App extends Component {
    render() {
        return (
          <div className="container">
           <MainApp />
          </div>
        );
    }
}
export default App;